import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import BrandedCard from '../../containers/BrandedCard/BrandedCard';
import ActivationCodeForm from './ActivationCodeForm';
import TwoButtonContainer from '../../containers/TwoButtonContainer';
import {ActivationCodeAuthorizationEvidence} from "../../../../types/authorizationEvidence";
import {authorizeByActivationCode} from "../../../services/pimsClient";
import {ClientConfigContext} from "../../../clientContext";
import {useMsal} from "@azure/msal-react";
import getAccessToken from "../../../util/getAccessToken";
import {getMyChartSamlSSOFromSystemAndBrand} from "../../../util/clientSideMyChartUtil";
import {BrandProps, ClientConfigType} from "../../../../types/clientConfigTypes";
import {ApiRequestLogger} from "../../../logger/apiRequestLogger";
import {OPENID_PARAMS} from "../../../../types/openid_enum";
import { sendEventByType } from '../../../services/amplitudeClient';
import { AmplitudeEventType } from '../../../../types/clientConfigTypes';
import {redirectWithSoftLogout} from "../../../util/sessionTimeout";

function ActivationCodePage({ brandInfo }: BrandProps): JSX.Element {
    // obtain a logger
    const log = new ApiRequestLogger({ eventSource: 'ActivationCodePage' });

    const history = useHistory();
    const {instance, accounts} = useMsal();
    const { handleSubmit, errors, control, formState } = useForm({ mode: 'onChange' });
    const clientConfiguration: ClientConfigType = useContext(ClientConfigContext);

    const search = document.location.search;
    const searchParams = new URLSearchParams(search);
    const isCrossRegion: boolean = searchParams.get(OPENID_PARAMS.CROSS_REGION) === "true";
    const redirectUrl = searchParams.get(OPENID_PARAMS.REDIRECT_URI) || searchParams.get(OPENID_PARAMS.REDIRECT_URL) as string;
    const brand = searchParams.get(OPENID_PARAMS.BRAND) as string;

    const [jwt, setJwt] = useState("");
    const { isDirty, isValid } = formState;
    const {t} = useTranslation();

    useEffect(()=>{
        getAccessToken(instance, accounts, clientConfiguration.b2cTenantConfig.scopes, log).then((jwt) =>{
            sendEventByType(AmplitudeEventType.EHRActivationCode_CodeEntry, jwt);
            setJwt(jwt);
        })
    },[])

    const onSubmit = async (data: any) => {
        sendEventByType(AmplitudeEventType.EHRActivationCode_CodeEntry_Next, jwt);
        history.push("/creating");
        const authorizationEvidence: ActivationCodeAuthorizationEvidence = {
            activationCode: data.activationCode,
            last4SSN: data.ssn,
            dateOfBirth: data.birthdate,
        };
        try {

            const response = await authorizeByActivationCode({jwt, authorizationEvidence, pimsApiUrl: clientConfiguration.pimsApiUrl});
            if (response.ok) {
                const body = await response.json();
                if (!body.authorizedSystem) {
                    log.error(`No AuthSystem, redirectUrl: ${redirectUrl}`);
                    sendEventByType(AmplitudeEventType.EHRActivationCode_CodeEntry_Invalid, jwt);
                    history.push("/error");
                    return;
                }
                sendEventByType(AmplitudeEventType.EHRAuthorized, jwt);
                // Cross Region is for MyChart-SAML flow. No other clients use set cross_region to true
                if (isCrossRegion) {
                    const mychartSamlSSOUrl = getMyChartSamlSSOFromSystemAndBrand(clientConfiguration, body.authorizedSystem, brand);
                    if (mychartSamlSSOUrl) {
                        redirectWithSoftLogout(instance, mychartSamlSSOUrl as string);
                    } else {
                        log.error(`Invalid MyChart SAML SSO URL: authorizedSystem: ${body.authorizedSystem}, brand: ${brandInfo.brand}`);
                        history.push("/error");
                    }
                } else {
                    log.debug(`Go to redirectURL: ${redirectUrl}`);
                    redirectWithSoftLogout(instance, redirectUrl as string);
                }
            } else {
                log.error(`ActivationCodePage(): Error occurred - ${response.status}${response.statusText ? ` -  ${response.statusText}`: ''}`);
                sendEventByType(AmplitudeEventType.EHRActivationCode_CodeEntry_Invalid, jwt);
                history.push("/phone-expired");
            }
        } catch (error) {
            log.error(`ActivationCodePage(): Error occurred - ${error}`);
            sendEventByType(AmplitudeEventType.EHRActivationCode_CodeEntry_Invalid, jwt);
            history.push("/phone-expired");
        }
    };

    return(
        <>
            <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <BrandedCard brand={brandInfo} >
                        {{
                            header: (<>
                                <Typography variant="h2">
                                    {t('Enter your activation code')}
                                </Typography>
                            </>),
                            content: (<>
                                <ActivationCodeForm control={control} errors={errors} translation={t} brandInfo={brandInfo} />
                            </>),
                            actions: (<>
                                <TwoButtonContainer
                                    firstButtonProps={{ variant: "text", label: t("Skip"), link: "/phone", brand: brandInfo.brand }}
                                    secondButtonProps={{ variant: "contained", disabled: !isDirty || !isValid , label: t("Next"), brand: brandInfo.brand }}
                                />
                            </>)
                        }}
                    </BrandedCard>
                </form>
            </div>
        </>
    );
}

export default ActivationCodePage;
